import React from "react";
export type Props = { name: string };
export default function JobFront(props: Props) {
  var job_name = "";
  switch (props.name) {
    case "ux": {
      job_name = "Product Designer";
      break;
    }
    case "software": {
      job_name = "Software Engineer";
      break;
    }
    case "software_senior": {
      job_name = "Seinor Software Engineer";
      break;
    }
    case "data": {
      job_name = "Data Scientist";
      break;
    }
  }
  return (
    <div className="wrapper">
      <div className="row">
        <div className="col1">
          <h1 className="hero-heading-2">
            {job_name}
            <span className="text-span-h1-punct">
              <br />
              {props.name.includes("senior")
                ? "Senior Jobs in US"
                : "Junior Jobs in US"}
            </span>
          </h1>
          <p className="hero-paragraph-ver2">
            It is <strong>hard</strong> to find a {job_name} now. I created this
            site to help people to find their jobs.
          </p>
          <br />
          <p>If you find this website useful, please support my development.</p>
          <a href="https://www.buymeacoffee.com/cczhong" target="_blank">
            <img
              src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
              alt="Buy Me A Coffee"
              className="buymecoffee"
            />
          </a>
          <br />
        </div>
        <div className="col2"></div>
        <div className="col3">
          <img
            src="https://cdn.aarp.net/content/dam/aarp/work/job-search/2020/08/1140-new-job-keyboard.web.jpg"
            alt=""
            height="300"
          />
        </div>
      </div>
    </div>
  );
}
