import React from "react";
import logo from "./logo.svg";
import "./App.css";
import JobPage from "./JobPage";
import { ApolloProvider } from "@apollo/client";

import { ApolloClient, InMemoryCache } from "@apollo/client";
import {
  HashRouter as Router,
  Route,
  Link,
  RouteComponentProps,
} from "react-router-dom";
type TParams = { id: number };
function App() {
  const client = new ApolloClient({
    uri: "https://job_server.tczhong.com/graphql",
    cache: new InMemoryCache(),
  });
  return (
    <ApolloProvider client={client}>
      <div className="App">
        <Router>
          <div>
            <Route path="/software">
              <JobPage type="software" />
            </Route>
            <Route path="/senior_software">
              <JobPage type="senior_software" />
            </Route>
            <Route path="/ux">
              <JobPage type="ux" />
            </Route>
            <Route path="/data">
              <JobPage type="data" />
            </Route>
          </div>
        </Router>
      </div>
    </ApolloProvider>
  );
}

export default App;
